.grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -24px;
    /* gutter size offset */
    min-width: 1192px;

    @media (max-width: 1240px) {
        min-width: 888px;
    }

    @media (max-width: 936px) {
        min-width: 584px;
    }

    @media (max-width: 632px) {
        min-width: 280px;
    }
}

.grid_column {
    padding-left: 24px;
    /* gutter size */
}

/* Style your items */
.grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 24px;
}