@import 'variables';

:root {
    /* Set the default values */
    --selected-overflow: var(--dark-overflow);
    --selected-base: var(--dark-base);
    --selected-surface: var(--dark-surface);
    --selected-overlay-dark: var(--dark-overlay-dark);
    --selected-overlay-light: var(--dark-overlay-light);
    --selected-text-main: var(--dark-text-main);
    --selected-text-secondary: var(--dark-text-secondary);
    --selected-border-color: var(--dark-border-color);

    --selected-img-filter: var(--dark-img-filter);

    --selected-font-family: var(--helvetica-fonts);
    --selected-font-size: 20px;

    /* Font family options */
    --helvetica-fonts: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --playfair-fonts: 'Playfair Display', 'serif';
    --mono-fonts: 'Curtive Mono', 'monospace';
    --roboto-fonts: 'Roboto', 'sans-serif';
    --inter-fonts: 'Inter', 'sans-serif';

    /* Set the light theme */
    --light-overflow: #e5e5e5;
    --light-base: #fafafa;
    --light-surface: #ffffff;
    --light-overlay-dark: #9d9d9d;
    --light-overlay-light: #efefef;
    --light-text-main: #323232;
    --light-text-secondary: #9d9d9d;
    --light-border-color: #e8e8e8;
    --light-img-filter: brightness(1) contrast(1);

    /* Set the sepia theme */
    --sepia-overflow: ;
    --sepia-base: #f5eddd;
    --sepia-surface: ;
    --sepia-overlay-dark: ;
    --sepia-overlay-light: ;
    --sepia-text-main: #262523;
    --sepia-text-secondary: #8d8a80;
    --sepia-border-color: #cec9b9;
    --sepia-img-filter: brightness(1) contrast(1);

    /* Set the dark theme */
    --dark-overflow: #010101;
    --dark-base: #1f2023;
    --dark-surface: #27292d;
    --dark-overlay-dark: #2d2f34;
    --dark-overlay-light: #383b40;
    --dark-text-main: #e9e9e9;
    --dark-text-secondary: #b1b1b2;
    --dark-border-color: rgba(255, 255, 255, 0.2);
    --dark-img-filter: brightness(0.8) contrast(1.2);

    /* Set the black theme */
    --black-overflow: #000000;
    --black-base: #010101;
    --black-surface: #1f2023;
    --black-overlay-dark: #27292d;
    --black-overlay-light: #2d2f34;
    --black-text-main: #e9e9e9;
    --black-text-secondary: #b1b1b2;
    --black-border-color: rgba(255, 255, 255, 0.1);
    --black-img-filter: brightness(0.8) contrast(1.2);

    /* Set primary color */
    --primary-background: #1da1f2;
}

/* Detect and Set theme via system preference on Mac */
@media (prefers-color-scheme: light) {
    :root {
        --selected-overflow: var(--light-overflow);
        --selected-base: var(--light-base);
        --selected-surface: var(--light-surface);
        --selected-overlay-dark: var(--light-overlay-dark);
        --selected-overlay-light: var(--light-overlay-light);
        --selected-text-main: var(--light-text-main);
        --selected-text-secondary: var(--light-text-secondary);
        --selected-border-color: var(--light-border-color);
        --selected-img-filter: var(--light-img-filter);
    }
}