*,
::before,
::after {
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

/* Hide scroll bar for IE and Edge */
// * {
// 	-ms-overflow-style: none;
// }

/* Hide scroll bar for Chrome, Safari and Opera */
// * ::-webkit-scrollbar {
// 	display: none;
// }

html {
    background-color: var(--selected-base);
    color: var(--selected-text-main);
}

a {
    text-decoration: none;
    color: var(--selected-text-main);
}